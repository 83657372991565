import { createChatBotMessage } from 'react-chatbot-kit';
import MyAvatar from "../myAvatar/myAvatar";

const config = {
  botName: 'GydTech AI',
  initialMessages: [createChatBotMessage(`Bonjour 👋 Voulez-vous découvrir comment l'intelligence artificielle peut être utilisée dans votre domaine d’activité ?`, {})],
  customComponents: {
    botAvatar: (props) => <MyAvatar {...props} />
  },
  state: {},
  idQt: null
};

export default config;
