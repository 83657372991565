import './App.css';
import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import config from './bot/config.js';
import MessageParser from './bot/MessageParser.js';
import ActionProvider from './bot/ActionProvider.js';
import React, {  useEffect, useState } from 'react';
import {createQt, invite} from "./api/service";

function App() {
    const [idchat, setIdchat] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const getIdChat = async () => {
                    try {
                        const data = await createQt();
                        if (data.data.id) {
                            setIdchat(data.data.id);
                            localStorage.setItem('chatId', data.data.id)
                            try {
                                const inv = await invite(data.data.id, 'Bonjour 👋 Voulez-vous découvrir comment l\'intelligence artificielle peut être utilisée dans votre domaine d’activité ?');
                                console.log(inv)
                            } catch (error) {
                                console.error(error);
                            }
                        }
                    } catch (error) {
                        console.error(error);
                    }
                };

                try {
                    await getIdChat();
                } catch (error) {
                    console.error(error);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

  return (
      <div>
          <Chatbot
              config={config}
              headerText='GYDTECH AI'
              messageParser={MessageParser}
              actionProvider={ActionProvider}
              placeholderText='Message'
              validator={(msg) => msg.length > 0}
          />
          <div className={'chatId'}>
              Propulsé par GYD Tech   ({idchat})
          </div>
      </div>
  );
}

export default App;
