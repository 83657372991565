import React  from 'react'
import { Avatar } from '@mui/material'
import './myAvatar.css'


const MyAvatar = () => {
    return (
        <Avatar
        alt="bot"
        src={"/assets/profil.png"}
        // sx={{ width: 50, height: 50 }}
      />
    )
}

export default MyAvatar
