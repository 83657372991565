import axios from 'axios'
const base_url = 'https://hammel.mychatassist.fr'

export const createQt = async () => {

  try {
    return await axios({
      method: 'post',
      url: `${base_url}/chat`
    })
  } catch (error) {
    console.error(error)
  }

}

export const postQuestions = async (idQt, qt) => {

  try {
    return await axios({
      method: 'post',
      url: `${base_url}/chat/demo/${idQt}/message`,
      data: qt
    })
  } catch (error) {
    console.error(error)
  }

}

export const invite = async (idQt, message) => {

  try {
    return await axios({
      method: 'post',
      url: `${base_url}/chat/${idQt}/ai-message`,
      data: {message}
    })
  } catch (error) {
    console.error(error)
  }

}

export const inviteChat = async (idQt) => {

  try {
    return await axios({
      method: 'put',
      url: `${base_url}/chat/${idQt}/invite`,
      data: {}
    })
  } catch (error) {
    console.error(error)
  }

}

export const closeChat = async (idQt) => {

  try {
    return await axios({
      method: 'put',
      url: `${base_url}/chat/${idQt}/close`,
      data: {}
    })
  } catch (error) {
    console.error(error)
  }

}

export const getForm = async (idQt) => {

  try {
    return await axios({
      method: 'get',
      url: `${base_url}/chat/${idQt}`
    })
  } catch (error) {
    console.error(error)
  }

}
